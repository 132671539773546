<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-xs-12 p-t-1">
        <Search :categories="filters.categories" />
      </div>
    </div>
    <ProductList :defFilter="category.filter" />
  </div>
</template>

<script>
import ProductList from 'Components/ProductList';
import Search from 'Components/Search';
import Categories from 'Lib/enums/Categories';
import { updateMeta, removeCanonical, setCanonical } from 'Lib/Utils';

export default {
  name: 'Products',
  components: { Search, ProductList },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    cat() {
      return this.$route.params.cat;
    },
    subcat() {
      return this.$route.params.subcat;
    },
    filters() {
      return this.category?.filters || {};
    },
    category() {
      return Categories.find(el => el.slug === this.cat);
    },
    subcategory() {
      return this.category.filters.categories.find(el => el.slug === this.subcat);
    },
  },
  methods: {
    setCanonical() {
      const { path } = this.$route;
      setCanonical(path);
    },
    setBreadcrumbs() {
      const breadcrumbs = [];
      const cat = this.category;
      if (cat) {
        const subcat = this.subcategory;
        breadcrumbs.push({ to: subcat ? `/${cat.slug}` : this.$route.path, title: cat.title });
        if (subcat) {
          breadcrumbs.push({ to: this.$route.path, title: subcat.name });
        }
      }
      this.$store.dispatch('setBreadcrumbs', breadcrumbs);
    },
    updateMeta() {
      const page = this.$vfPages.getByRoute(this.$route.path);
      if (page) {
        updateMeta(page.metaInfo);
      } else {
        const {
          category: { title, metaInfo },
          subcategory,
        } = this;
        const meta = {
          title: metaInfo.title || `${title} | VaporFly`,
          description: metaInfo.description,
        };
        if (subcategory) {
          meta.title = `${subcategory.name} - ${meta.title}`;
          meta.description = `${subcategory.name} - ${meta.description}`;
        }
        updateMeta(meta);
      }
    },
  },
  watch: {
    cat() {
      this.updateMeta();
      this.setCanonical();
      this.setBreadcrumbs();
    },
    subcat() {
      this.updateMeta();
      this.setCanonical();
      this.setBreadcrumbs();
    },
  },
  beforeMount() {
    this.$store.dispatch('clearSearch');
    this.updateMeta();
    this.setCanonical();
    this.setBreadcrumbs();
  },
  beforeDestroy() {
    removeCanonical();
  },
};
</script>

<style scoped></style>
