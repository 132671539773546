<template>
  <div class="container" style="margin-bottom: 2rem">
    <div class="row">
      <template v-for="(item, i) in products">
        <ItemTile
          :item="item"
          show-add-to-cart
          :key="i"
          class="col-lg-3 col-xs-12"
          :loading="loading"
          :prefix="$route.path"
        />
      </template>
      <div class="col-lg-12 my-2" v-if="navigation && (loading || showLoadMore)">
        <template v-if="showLoadMore">
          <Pager
            :total="collection.pagesAmount"
            :active="page"
            :standalone="standalonePager"
            standalone-type="router-link"
          />
          <template v-if="loading">
            <span class="rounded-5 button w-auto bg-gray text-bold m-auto loading-bg mt-3">Loading</span>
          </template>
          <template v-else-if="nextPageItemsAmount > 0">
            <span
              class="rounded-5 button w-auto bg-gray text-bold m-auto p-tb-5"
              style="margin-top: 2rem; margin-bottom: 2rem"
              @click="loadMore"
            >
              Load next {{ nextPageItemsAmount }} {{ pluralize('item', nextPageItemsAmount) }}
            </span>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTile from 'Components/ItemTile';
import Pager from 'Components/Pager';
import { pluralize, deepMerge } from 'Lib/Utils';

export default {
  name: 'ProductList',
  props: {
    defFilter: {
      type: Object,
      default: () => ({}),
    },
    navigation: {
      type: Boolean,
      default: () => true,
    },
  },
  components: { Pager, ItemTile },
  methods: {
    pluralize,
    setLoading(state) {
      this.loading = state;
      this.$store.dispatch('loader', state);
    },
    setParams(params) {
      const { type, additional, ...rest } = params || {};
      let searchParams = deepMerge(rest, this.defFilter || {}, { types: [] });
      if (type) {
        if (type < 0) {
          switch (type) {
            case -1: // Disposable pens
              searchParams['types'] = [4, 131, 18];
              searchParams['not_types'] = [81];
              // searchParams['features'] = JSON.stringify([{ 15: ['28'] }]);
              break;
            case -2: // Pod systems
              searchParams['types'] = [4, 131, 32]; // AND
              // searchParams['types.id'] = [76, 133]; // OR
              searchParams['not_types'] = [87, 132];
              break;
          }
        } else {
          searchParams['types'].push(type);
        }
      }
      if (additional) {
        searchParams = deepMerge(searchParams, additional);
      }
      if (this.searchParams.type && this.searchParams.type !== type) {
        this.page = 1;
      }
      this.$set(this.$data, 'searchParams', searchParams);
    },
    navigate(page) {
      const prev = Array.isArray(this.page) ? this.page[this.page.length - 1] : this.page;
      if (page - prev === 1) {
        this.loadMore();
      } else {
        this.page = page;
        this.load();
      }
    },
    load() {
      this.setLoading(true);
      this.$api
        .getProductsCollection(
          {
            itemsPerPage: this.itemsPerPage,
            page: this.page,
            properties: [
              'id',
              'name',
              'defaultImage',
              'tradingPrice',
              'images',
              'slug',
              'types',
              'constructorImages',
              'moq',
            ],
            ...(this.searchParams || {}),
          },
          !this.isLoggedIn
        )
        .then(collection => {
          this.collection = collection;
          this.$set(this.$data, 'products', collection.items);
          this.setLoading(false);
          this.$store.dispatch('searchedTotal', collection.total);
        })
        .catch(this.onError);
    },
    loadMore() {
      if (this.standalonePager) {
        const url = new URL(window.location.href);
        url.searchParams.set('page', this.page + 1);
        this.$router.push(url.pathname + url.search);
        return;
      }
      this.setLoading(true);
      if (Array.isArray(this.page)) {
        this.page = [...this.page, this.page[this.page.length - 1] + 1];
      } else {
        this.page = [this.page, this.page + 1];
      }
      this.collection
        .loadNextPage()
        .then(collection => {
          this.collectionPage = collection.currentPage;
          this.$set(this.$data, 'products', collection.items);
          this.$slugger.batchUpdate(collection.items.map(item => [item.slug, item.id]));
          this.setLoading(false);
        })
        .catch(this.onError);
    },
    onError() {
      this.setLoading(false);
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    showLoadMore() {
      return this.collection && this.collection.size < this.collection.total;
    },
    nextPageItemsAmount() {
      const size = this.itemsPerPage;
      const current = size * this.collectionPage;
      return Math.min(size, this.collection.total - current);
    },
    qPage() {
      const { page } = this.$route.query;
      return +page || 1;
    },
  },
  data() {
    return {
      products: [],
      loading: false,
      collection: null,
      itemsPerPage: 32,
      page: 1,
      collectionPage: 1,
      searchParams: {},
      standalonePager: true,
    };
  },
  watch: {
    searchParams() {
      this.load();
    },
    qPage(page) {
      this.page = +page || 1;
      this.load();
      window.scrollTo(0, 0);
    },
    defFilter(filter) {
      this.setParams(filter);
    },
  },
  mounted() {
    this.page = +this.$route.query.page || 1;
    this.setParams(this.$store.getters.search);
    this.unsubSearch = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'search') {
        this.setParams(state.search.filters);
      }
    });
    setTimeout(() => {
      if (!this.collection) {
        this.load();
      }
    });
  },
  beforeDestroy() {
    this.unsubSearch();
  },
};
</script>
