<template>
  <label class="checkbox" :class="{ checked }">
    <i>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1000 1000"
        enable-background="new 0 0 1000 1000"
        xml:space="preserve"
      >
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path
            class="outer-square"
            d="M1338.1,4984c-553.7-121.5-978.8-504.6-1172.7-1051.2L100,3745.9V113.3v-3632.6l63.1-177.5c149.5-420.5,418.2-733.5,791.9-918.1c380.8-189.2,121.5-177.5,4039.1-177.5c3919.9,0,3658.3-11.7,4041.4,177.5c308.4,154.2,532.6,378.5,686.8,686.8c189.2,383.1,177.5,121.5,177.5,4032.1c0,2399.2-7,3525.1-25.7,3620.9c-102.8,563-502.3,1018.5-1069.9,1219.4l-177.5,63.1l-3574.2,4.7C2151.1,5014.4,1452.6,5009.7,1338.1,4984z M8516.9,3886.1c114.5-49.1,189.2-121.5,240.6-233.6c42.1-88.8,44.4-238.3,44.4-3534.5c0-3053.3-4.7-3450.4-35-3527.5c-49.1-114.5-121.5-189.2-233.6-240.6c-88.8-42.1-238.3-44.4-3539.2-44.4s-3450.4,2.3-3539.2,44.4c-112.1,51.4-184.5,126.1-233.6,240.6c-30.4,77.1-35,474.2-35,3522.8c0,3048.6,4.7,3445.7,35,3522.8c49.1,114.5,121.5,189.2,233.6,240.6c88.8,42,238.3,44.4,3534.5,44.4C8042.7,3921.1,8439.8,3916.4,8516.9,3886.1z"
          />
          <path
            v-if="checked"
            class="checkmark"
            d="M6923.7,2313.9c-151.8-49.1-170.5-67.8-1476.4-1464.7C4846.9,206.8,4347-323.6,4333-328.2c-11.7-4.7-271,240.6-574.7,542c-607.4,607.4-661.1,647.1-901.7,647.1c-411.2,0-698.5-387.8-584-787.3c37.4-133.2,53.7-149.5,1058.2-1156.4c560.7-563,1025.5-1023.2,1032.6-1023.2c32.7,0,3242.5,3450.4,3298.5,3543.8c140.2,240.6,100.4,516.3-105.1,721.8C7372.2,2344.3,7162,2393.3,6923.7,2313.9z"
          />
        </g>
      </svg>
    </i>
    <span v-if="label" class="checkbox-label">{{ label }}</span>
    <input type="checkbox" v-bind="inputProps" v-model="checked" v-on:change="change" />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    inputProps: {
      type: Object,
      default: () => ({}),
    },
    label: String,
    value: Boolean,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    change() {
      this.$emit('change', this.checked);
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  created() {
    this.checked = !!this.value;
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: max-content;
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;

  input[type='checkbox'] {
    display: none;
  }

  .checkbox-label {
    display: inline;
  }

  &.reversed {
    flex-direction: row-reverse;

    i {
      margin: 0 0 0 10px;
    }
  }

  i {
    width: 16px;
    height: 16px;
    position: relative;
    margin: 0 10px 0 0;

    &.small {
      width: 12px;
      height: 12px;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #d2d2d2;
      }
    }
  }
  &.checked {
    svg {
      path.checkmark {
        fill: #96c22e !important;
      }
    }
  }
}
</style>
