<template>
  <div class="container p-tb-2">
    <form class="search-form search-page rounded-5 bordered-light p-5px" @submit="submit">
      <input class="icon-left-small search-icon" type="text" name="search" placeholder="Search" v-model="value" />
      <span class="tag" style="display: none">
        <span>E-cig</span>
        <span class="close">×</span>
      </span>
      <button
        type="submit"
        class="button bg-light-green text-color-white text-bold rounded-5 uppercase bordered-light p-25"
      >
        Search
      </button>
    </form>
    <div class="filters-block" v-if="categories && categories.length">
      <div class="filters-section categories">
        <div class="filter-item-wrapper">
          <router-link :to="parentLink" class="filter-item badge" :class="{ active: catId === void 0 }">
            All
          </router-link>
        </div>
        <div class="filter-item-wrapper" v-for="(cat, i) in categories" :key="i">
          <router-link :to="getCatLink(cat.slug)" class="filter-item badge" :class="{ active: isActiveCat(cat.id) }">
            {{ cat.name }}
          </router-link>
        </div>
      </div>
      <div class="additional-filters-row">
        <div class="filter-item-wrapper">
          <Checkbox label="In stock" v-model="filters.inStock" />
        </div>
        <div class="filter-item-wrapper">
          <Checkbox label="Customizing" v-model="filters.customizable" />
        </div>
      </div>
      <div class="total">
        <div v-if="total !== null" class="total-label">
          Found <span class="text-bold px-1">{{ total }}</span> {{ pluralize('product', total) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pluralize } from 'Lib/Utils';
import Checkbox from 'Components/Checkbox';
import { TYPE_IN_STOCK, TYPE_PDC_FRONT, TYPE_PDC_REAR, TYPE_SAMPLES_IN_STOCK } from 'Lib/enums/Types';
import { FP_TYPES_AND, FP_TYPES_OR } from 'Lib/enums/Filters';

export default {
  name: 'Search',
  components: { Checkbox },
  props: {
    categories: Array,
  },
  data() {
    return {
      value: '',
      total: 0,
      filters: {
        customizable: false,
        inStock: false,
      },
    };
  },
  computed: {
    searchIn() {
      return this.$store.getters.search.type;
    },
    catId() {
      const { subcat } = this.$route.params;
      return this.categories.find(el => el.slug === subcat)?.id;
    },
    parentLink() {
      return this.$route.path
        .split('/')
        .slice(0, 2)
        .join('/');
    },
  },
  watch: {
    catId(id) {
      this.setCategory(id);
    },
    filters: {
      deep: true,
      handler: function(data) {
        const { inStock, customizable } = data || {};
        let filters;
        if (inStock) {
          filters = {
            [FP_TYPES_OR]: [TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK],
          };
        }
        if (customizable) {
          filters = {
            [FP_TYPES_OR]: [TYPE_IN_STOCK, TYPE_SAMPLES_IN_STOCK],
            [FP_TYPES_AND]: [TYPE_PDC_FRONT, TYPE_PDC_REAR],
          };
        }
        if (filters) {
          this.$store.dispatch('search', { additional: filters });
        } else {
          this.$store.dispatch('search', { additional: {} });
        }
      },
    },
  },
  methods: {
    pluralize,
    search(value) {
      this.$store.dispatch('search', { name: value });
    },
    submit(e) {
      e.preventDefault();
      this.search(this.value);
      return false;
    },
    setCategory(id) {
      const payload = { type: id };
      if (this.isActiveCat(id)) {
        payload.type = null;
      }
      this.$store.dispatch('search', payload);
    },
    isActiveCat(id) {
      return this.searchIn === id;
    },
    getCatLink(slug) {
      if (this.$route.params.subcat !== void 0) {
        return this.$route.path.replace(/\/[^/]+$/, '/' + slug);
      } else {
        return `${this.$route.path}/${slug}`;
      }
    },
  },
  mounted() {
    this.value = this.$store.getters.quickSearch;
    this.unsubSearch = this.$store.subscribe(mutation => {
      if (mutation.type === 'search') {
        this.value = this.$store.getters.search.name;
      }
      if (mutation.type === 'searchedTotal') {
        this.total = mutation.payload;
      }
    });
  },
  created() {
    const { subcat } = this.$route.params;
    const id = this.categories.find(el => el.slug === subcat)?.id;
    this.$store.dispatch('search', { type: id });
  },
  beforeDestroy() {
    this.unsubSearch();
  },
};
</script>

<style type="text/css" lang="scss">
form.search-form {
  position: relative;
}

.btn-small {
  height: 30px !important;
}

.popup-panel {
  display: block;
  position: absolute;
  z-index: 999;
  width: 100%;
  padding: 15px 0;
  border: 1px solid #e5e5e5;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  transition: all 1s;

  &.hidden {
    display: none;
  }

  .results-wrapper {
    display: flex;
    flex-direction: row;

    .results-label {
      font-size: 16px;
      width: 100%;
      align-self: center;
    }
  }
}

.badge {
  padding: 10px 15px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.total .total-label {
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
}

.filters-block {
  width: 100%;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .filter-item-wrapper {
    display: block;
    position: relative;
    padding: 5px;

    .filter-item {
      display: inline-block;

      &.active {
        border-color: #96c22e;
      }
    }
  }

  .filters-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    flex-grow: 2;
    margin: 0 -5px 10px;
    max-width: 100%;
    order: 0;
  }

  .additional-filters-row {
    order: 3;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 -5px;
  }

  .total {
    display: flex;
    align-items: center;
    text-align: right;
    order: 1;
  }
}
@media only screen and (max-width: 640px) {
  .additional-filters-row {
    order: 1 !important;
    margin-bottom: 10px !important;
  }
  .total {
    align-self: center !important;
  }
}
</style>
