<template>
  <div class="pager" v-if="items.length > 1">
    <div class="pager-item" v-for="(item, i) in items" :key="i">
      <template v-if="standalone && !isNaN(item)">
        <a v-if="standaloneType === 'link'" class="inner" :class="{ active: isActive(item) }" :href="buildLink(item)">
          {{ item }}
        </a>
        <router-link
          v-if="standaloneType === 'router-link'"
          class="inner"
          :class="{ active: isActive(item) }"
          :to="buildLink(item)"
        >
          {{ item }}
        </router-link>
      </template>
      <template v-else>
        <div class="inner" :class="{ active: isActive(item) }" @click="navigate(item)">
          {{ item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pager',
  props: {
    total: Number,
    active: [Number, Array],
    onNavigate: Function,
    standalone: Boolean,
    standaloneType: {
      type: String,
      validator: value => ['link', 'router-link'].includes(value),
    },
    linkTemplate: String,
    pageParam: String,
    linkBuilder: Function,
  },
  computed: {
    items() {
      const { active, total } = this;
      if (Array.isArray(active)) {
        return this.getItems(active[active.length - 1], total);
      }
      return this.getItems(active, total);
    },
  },
  methods: {
    buildLink(page) {
      const { linkBuilder, pageParam, linkTemplate } = this;
      if (typeof linkBuilder === 'function') {
        return linkBuilder(page);
      }
      const url = new URL(window.location.href);
      if (pageParam) {
        url.searchParams.set(pageParam, page);
        return url.href.replace(url.origin, '');
      }
      if (linkTemplate) {
        return linkTemplate.replace(/{page}/g, page);
      }
      url.searchParams.set('page', page);
      return url.href.replace(url.origin, '');
    },
    isActive(page) {
      if (Array.isArray(this.active)) {
        return this.active.includes(page);
      } else {
        return page === +this.active;
      }
    },
    getItems(active, total) {
      const visible = [];
      if (total <= 9) {
        for (let i = 1; i <= total; i++) {
          visible.push(i);
        }
        return visible;
      }
      const head = [];
      const tail = [];
      const start = Math.max(1, active - 2);
      const end = Math.min(total, start + 4);
      for (let i = start; i <= end; i++) {
        visible.push(i);
      }
      if (start > 1) {
        head.push(1);
      }
      if (start > 3) {
        head.push('...');
      }
      if (end < total - 2) {
        tail.push('...');
      }
      if (end < total) {
        tail.push(total);
      }
      if (start < 4) {
        let _end = end;
        if (start === 3) {
          visible.unshift(2);
        }
        while (visible.length < 7 - head.length) {
          visible.push(++_end);
        }
      }
      if (end > total - 4) {
        let _start = start;
        if (end === total - 2) {
          visible.push(total - 1);
        }
        while (visible.length < 7 - tail.length) {
          visible.unshift(--_start);
        }
      }
      return [...head, ...visible, ...tail];
    },
    navigate(page) {
      if (isNaN(page) || this.isActive(page)) {
        return;
      }
      if (typeof this.onNavigate === 'function') {
        this.onNavigate(page, +this.active);
      }
    },
  },
};
</script>

<style type="text/css" lang="scss">
.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;

  &-item + &-item {
    margin-left: 8px;
  }

  &-item {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
    cursor: pointer;
    font-weight: 500;
    color: #494b4d;

    .inner {
      position: relative;
      z-index: 5;
      display: inline-block;
      min-width: 48px;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      text-align: center;
      font-size: 20px;
      line-height: 40px;

      &.active {
        border-color: #96c22e !important;
        cursor: default;
      }
      &:hover {
        color: #96c22e;
      }
    }
  }
}
</style>
